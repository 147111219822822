import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Box, Theme, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { TitleFull } from '../../../../../config/api/types';
import security from '../../../../security';
import cart from '../../../cart';
import titleDetail from '../../../detail';
import { DistributionSection } from '../../../detail/components/DistributionSection';
import Prices from '../../../detail/components/Prices';
import { getOrderTime, getPriceData, getSupplyDate, showOrderTime } from '../../../domain';

const statusHeight = 30;

const useStyles = makeStyles((theme: Theme) => ({
  orderPanel: {
    position: 'relative',
    border: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(1),
    paddingTop: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    marginTop: statusHeight / 2,
  },
  status: {
    marginRight: 0,
    textAlign: 'right',
    fontSize: theme.typography.caption.fontSize,
    whiteSpace: 'nowrap',
  },
  price: {
    ...theme.typography.h6,
    fontSize: '1rem',
  },
  tax: {
    // ...theme.typography.caption,
  },
  supplyDate: {
    marginTop: -theme.spacing(0.5),
    float: 'right',
  },
  orderTime: {
    marginTop: -theme.spacing(0.5),
    float: 'right',
  },
  addToCart: {
    float: 'none',
  },
}));

type Props = {
  title: TitleFull;
};

const TitleCta = ({ title }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const roles = useSelector(security.selectors.getUserRole);

  const orderAtTexts = roles
    .map((role) =>
      title.fund?.distributor?.isCbMember
        ? t('title_order_cb_at', { context: role })
        : t('title_order_at', { context: role })
    )
    .filter(Boolean)[0];
  const orderAtUnknownTexts = roles
    .map((role) => t('title_order_at_unknown_catalog', { context: role }))
    .filter(Boolean)[0];

  const hasText = title.fund ? (!title.fund.isAvailable ? orderAtTexts : orderAtUnknownTexts) : '';

  const priceInfos = getPriceData(title);
  const isActionPrice =
    priceInfos[0].label === 'price_action' || priceInfos[0].label === 'price_gbp_action';

  return (
    <div className={classes.orderPanel}>
      <titleDetail.TitleStatus availability={title.availability} className={classes.status} />
      {isActionPrice && <titleDetail.PriceTagIcon />}
      {showOrderTime(title, roles) && (
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'flex-end'}
          className={classes.orderTime}
        >
          <AccessTimeIcon style={{ marginRight: '0.2rem', fontSize: '1rem' }} />
          <Typography display="inline">
            {t('title_orderTime', {
              context: getOrderTime(title).translationKey,
              days: getOrderTime(title).days,
            })}
          </Typography>
        </Box>
      )}
      {title.availability.supplyDate && (
        <Typography align="right" className={classes.supplyDate}>
          {t('title_expected_on_short', {
            date: getSupplyDate(title),
          })}
        </Typography>
      )}
      <Prices
        title={title}
        classes={{ price: classes.price, tax: classes.tax }}
        compact
        suffix={
          title.discountCode && (
            <Tooltip title={title.discountCode.label} arrow>
              <Typography display="inline" style={{ marginLeft: 4, position: 'relative', top: -1 }}>
                ({title.discountCode.code})
              </Typography>
            </Tooltip>
          )
        }
        priceInfos={priceInfos}
      />

      <Box display="flex" justifyContent="flex-end">
        <cart.components.AddToCart title={title} condensed className={classes.addToCart} />
      </Box>

      {hasText && (
        <Box textAlign="right" marginTop={1.5} style={{ float: 'right' }}>
          {!title.fund?.isAvailable ? (
            <>
              <DistributionSection
                fund={title.fund}
                availability={title.availability}
                publisher={title.publisher}
              />
            </>
          ) : (
            <Typography variant="body2" display="inline">
              {orderAtUnknownTexts}
            </Typography>
          )}
        </Box>
      )}
    </div>
  );
};

export default TitleCta;
