import CloseIcon from '@mui/icons-material/Close';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { OptionsObject, SnackbarProvider } from 'notistack';
import React, { ElementType, RefObject } from 'react';
import { connect } from 'react-redux';
import {
  Redirect,
  Route,
  RouteComponentProps,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import Button from '../../../components/Button';
import { ApiRouteContext } from '../../../config/api/ApiRouteContext';
import { User } from '../../../config/api/types';
import { ClientRouteContext } from '../../../config/routes/ClientRouteContext';
import buildRoutes from '../../../config/routes/buildRoutes';
import ROUTE_KEY from '../../../config/routes/routeKeys';
import { RootState } from '../../../config/store/types';
import alerts from '../../core/alerts';
import security from '../../security';
import Header from '../components/Header';
import AppInfo from '../components/Info/AppInfo';
import Main from '../components/Main';
import PublicHeader from '../components/PublicHeader';
import useFetchApiRoutes from '../hooks/useFetchApiRoutes';
import RedirectToLoginOnLogout from './RedirectToLoginOnLogout';

type MappedState = {
  isAuthenticated: boolean;
  user?: User;
};

type Props = MappedState & {};
const useStyles = makeStyles((theme: Theme) => ({
  rootNotistack: {
    top: theme.toolbar.height + Number(theme.spacing(1).replace('px', '')),
  },
}));

const App = ({ isAuthenticated, user }: Props) => {
  const { apiRoutes: accessibleApiRoutes, isPending: apiRoutesPending } = useFetchApiRoutes();
  const { nestedRoutes, flatRoutes } = buildRoutes(accessibleApiRoutes);

  const classes = useStyles();
  const userPending = isAuthenticated && !user;

  const notistackRef: RefObject<SnackbarProvider> = React.createRef();
  const onClickDismiss = (key: OptionsObject['key']) => () => {
    notistackRef?.current?.closeSnackbar(key);
  };

  return (
    <SnackbarProvider
      ref={notistackRef}
      maxSnack={4}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      autoHideDuration={3000}
      classes={{ containerRoot: classes.rootNotistack }}
      action={(key) => (
        <Button onClick={onClickDismiss(key)} variant="text" round>
          <CloseIcon color="secondary" />
        </Button>
      )}
    >
      <ClientRouteContext.Provider value={{ nestedRoutes, flatRoutes }}>
        <ApiRouteContext.Provider value={{ routes: accessibleApiRoutes }}>
          <Router>
            <QueryParamProvider ReactRouterRoute={Route}>
              {!apiRoutesPending && !userPending && (
                <>
                  <Switch>
                    {flatRoutes.map((route, index) => (
                      <Route
                        key={route.path}
                        path={route.path}
                        exact
                        component={
                          route.component
                            ? (props: RouteComponentProps) => {
                                const Component = route.component as ElementType;

                                const isHomePage = route.key === ROUTE_KEY.LOGIN;

                                return (
                                  <>
                                    {isHomePage ? null : isAuthenticated ? (
                                      <Header />
                                    ) : (
                                      <PublicHeader />
                                    )}
                                    <Main
                                      clientRoutes={nestedRoutes}
                                      isAuthenticated={isAuthenticated}
                                      isHomePage={isHomePage}
                                      {...props}
                                    >
                                      <Component
                                        {...props}
                                        key={route.allowRemount ? new Date().toString() : undefined}
                                      />
                                    </Main>
                                  </>
                                );
                              }
                            : () => (
                                <Redirect
                                  to={
                                    index + 1 < flatRoutes.length ? flatRoutes[index + 1].path : '/'
                                  }
                                />
                              )
                        }
                      />
                    ))}
                    <Route
                      component={() => <Redirect to={isAuthenticated ? '/titels' : '/login'} />}
                    />
                  </Switch>
                </>
              )}
              <RedirectToLoginOnLogout />
            </QueryParamProvider>
          </Router>
          <alerts.containers.Alert />
          <AppInfo />
        </ApiRouteContext.Provider>
      </ClientRouteContext.Provider>
    </SnackbarProvider>
  );
};

const mapState = (state: RootState): MappedState => ({
  user: security.selectors.getUser(state),
  isAuthenticated: security.selectors.isAuthenticated(state),
});

export default connect(mapState)(App);
