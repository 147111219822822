import { Organisation, Prefix, ProductForm, User } from '../../config/api/types';
import { IsbnFormValues, PrefixApplicationFormValues } from './types';

export const calculatePrefixSizeBasedOnPrefix = (prefix?: string): number => {
  if (!prefix) {
    return 0;
  }

  return Math.pow(10, 12 - prefix.length);
};

export const IsbnPrices = {
  '1': '109,36',
  '10': '337,95',
  '100': '1310,59',
  flemishOld: '21,28',
};

export const defaultIsbnFormValues = (
  user?: User,
  organisation?: Organisation,
  prefix?: string
): IsbnFormValues => ({
  login: {
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    email: user?.email || '',
  },
  organisation: {
    name: organisation?.name || '',
    companyRegistrationNumber: organisation?.companyRegistrationNumber || '',
    street: organisation?.street || '',
    number: organisation?.number || '',
    addition: organisation?.addition || '',
    postalCode: organisation?.postalCode || '',
    city: organisation?.city || '',
    countryIso: organisation?.countryIso || '',
    email: organisation?.email || user?.email || '',
    phoneNumber: organisation?.phoneNumber || '',
    url: organisation?.url || '',
  },
  editionNumber: 1,
  prefix: prefix || '',
  requestIsbnNumbers: '',
  priceDescription: '',
  themas: [],
  themaQualifiers: [],
  selectedProductForm: {} as ProductForm,
  billingConsent: false,
  privacyConsent: Boolean(user),
});

export const canStartNewApplication = (prefix: Prefix): boolean =>
  Boolean(
    prefix.remainingIsbnCount &&
      prefix.active &&
      (prefix.billingType.code === 'FLEMISH_NEW' || prefix.billingType.code === 'FLEMISH_OLD')
  );

export const defaultPrefixApplicationFormValues = (user: User): PrefixApplicationFormValues => ({
  login: {
    firstName: user.firstName || '',
    lastName: user.lastName || '',
    email: user.email || '',
  },
  organisation: {
    name: user._embedded?.organisation?.name || '',
    companyRegistrationNumber: user._embedded?.organisation?.companyRegistrationNumber || '',
    street: user._embedded?.organisation?.street || '',
    number: user._embedded?.organisation?.number || '',
    addition: user._embedded?.organisation?.addition || '',
    postalCode: user._embedded?.organisation?.postalCode || '',
    city: user._embedded?.organisation?.city || '',
    countryIso: user._embedded?.organisation?.countryIso || '',
    email: user._embedded?.organisation?.email || user.email || '',
    phoneNumber: user._embedded?.organisation?.phoneNumber || '',
    url: user._embedded?.organisation?.url || '',
  },
  masterPrefixToUse: '',
  prefix: '',
  prefixSize: '',
  requestIsbnNumbers: '',
});
