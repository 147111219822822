import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Typography } from '@mui/material';
import { useFetch } from '@react-redux-fetch/hooks';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../components/Button';
import { TextField } from '../../../../../components/Form';
import Table from '../../../../../components/Table';
import { CellType, DataType } from '../../../../../components/Table/types';
import { Department, Departments, Maybe } from '../../../../../config/api/types';
import { useAsyncValidation } from '../../../../../helpers/finalFormAsyncValidation';
import { getLink } from '../../../../../helpers/hateoas';
import { deleteDepartmentRequest, updateDepartmentRequest } from '../../../api';

type Props = {
  departments: Maybe<Departments>;
};

const DepartmentsList = ({ departments }: Props) => {
  const { t } = useTranslation();
  const [editingRow, setEditingRow] = useState<Department | null>(null);
  const [departmentToDelete, setDepartmentToDelete] = useState<string | undefined>('');
  const [error, setError] = useState('');

  const [updateDepartmentResponse, updateDepartment] = useFetch(updateDepartmentRequest);
  const [deleteDepartmentResponse, deleteDepartment] = useFetch(deleteDepartmentRequest);

  const { createSubmissionPromise } = useAsyncValidation(updateDepartmentResponse);

  useEffect(() => {
    if (deleteDepartmentResponse?.rejected) {
      setError(deleteDepartmentResponse.reason?.message);
    }
  }, [deleteDepartmentResponse]);

  if (!departments) {
    return null;
  }

  const headers = [
    { id: 'name', label: t('table_headers_department_name') },
    { id: 'errors', label: '', width: error ? 160 : 0 },
    { id: 'acions', label: '', width: 80 },
  ];

  const data: DataType = {
    resource: 'departments',
    rows: departments.map((department) => {
      const isEditing = Boolean(editingRow && department.departmentId === editingRow.departmentId);

      return {
        id: getLink(department, 'self') || '',
        cells: [
          {
            type: CellType.Text,
            data: isEditing ? (
              <TextField name="title" label={t('form_department_name')} />
            ) : (
              department.title
            ),
          },
          {
            type: CellType.Text,
            data: Boolean(error && departmentToDelete === department.departmentId) && (
              <Typography color="error">{error}</Typography>
            ),
          },
          {
            type: CellType.Action,
            data: isEditing ? (
              <>
                <Button
                  variant="text"
                  round
                  aria-label="Done"
                  type="submit"
                  disabled={updateDepartmentResponse?.pending}
                  size="small"
                >
                  <DoneIcon>done_icon</DoneIcon>
                </Button>
                <Button
                  variant="text"
                  round
                  secondary
                  aria-label="Cancel"
                  onClick={() => setEditingRow(null)}
                  disabled={updateDepartmentResponse?.pending}
                  size="small"
                >
                  <CloseIcon>close_icon</CloseIcon>
                </Button>
              </>
            ) : (
              <>
                <Button
                  variant="text"
                  round
                  secondary
                  aria-label="Edit"
                  onClick={(e) => {
                    e.preventDefault();
                    return setEditingRow(department);
                  }}
                  size="small"
                >
                  <EditIcon>edit_icon</EditIcon>
                </Button>
                <Button
                  variant="text"
                  round
                  secondary
                  aria-label="remove"
                  onClick={() => {
                    setDepartmentToDelete(department.departmentId);
                    setError('');
                    deleteDepartment(`department/${department.departmentId}`);
                  }}
                  size="small"
                >
                  <DeleteIcon>delete_icon</DeleteIcon>
                </Button>
              </>
            ),
          },
        ],
      };
    }),
  };

  return (
    <Box style={{ marginBottom: 16, maxWidth: '400px' }}>
      <Form
        onSubmit={(values: Department) => {
          if (!values || !editingRow) {
            return;
          }
          const url = getLink(editingRow, 'self');

          if (url) {
            updateDepartment(url, values);
          }
          return createSubmissionPromise().then((err) => {
            if (!err) {
              setEditingRow(null);
            }
          });
        }}
        initialValues={editingRow}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Table
              data={data}
              headers={headers}
              page={1}
              pages={1}
              limit={50}
              total={1}
              hidePagination
            />
          </form>
        )}
      </Form>
    </Box>
  );
};

export default DepartmentsList;
