import { FetchConfig } from '@react-redux-fetch/core';
import i18next from 'i18next';
import memoizeOne from 'memoize-one';
import moment from 'moment';
import length from 'ramda/es/length';
import {
  Bookstore,
  BookstoreDto,
  CreateOrgLoginDTO,
  DataConsumer,
  DataConsumerDTO,
  OrgLogin,
  UpdateOrgLoginDTO,
} from '../../config/api/models/organisation';
import apiRoutes from '../../config/api/routes';
import {
  CbRelationDto,
  DataProducerDto,
  Department,
  DepartmentDto,
  Departments,
  DistributorDto,
  Organisation,
  OrganisationCreateRequest,
  PrefixDto,
} from '../../config/api/types';
import { Data } from '../../config/store/types';
import { ApiConfigUnion } from '../../helpers/apiConfig';
import { API_DATE_FORMAT, UI_DATE_FORMAT } from '../../helpers/date';
import { normalizePagedOrgLoginRequest } from './dtoMapper';

export const getLoginsRequest = (url: string): FetchConfig<Data> => {
  return {
    method: 'GET',
    url: apiRoutes.create(url),
    transform: normalizePagedOrgLoginRequest,
    repository: {
      orgLogins: (prev, next) => ({ ...prev, ...next }),
      orgLoginsPage: (prev, next) => next,
    },
  };
};

export const createLoginRequest = (url: string, body: CreateOrgLoginDTO): FetchConfig<Data> => {
  return {
    method: 'POST',
    url: apiRoutes.create(url),
    fetchOptions: {
      body,
      successMsg: i18next.t('form_organisation_update_success'),
    },
    repository: {
      orgLogins: (prev, next) => ({ ...prev }),
    },
  };
};

export const updateLoginRequest = (url: string, body: UpdateOrgLoginDTO): FetchConfig<Data> => {
  return {
    method: 'PUT',
    url: apiRoutes.create(url),
    fetchOptions: {
      body,
      successMsg: i18next.t('form_organisation_update_success'),
    },
    repository: {
      orgLogins: (prev, next: OrgLogin) => ({ ...prev, [next.email]: next }),
    },
  };
};

export const getPublishersRequest = (url: string): FetchConfig<Data> => {
  return {
    method: 'GET',
    url: apiRoutes.create(url),
    repository: {
      publishers: (prev, next) => next,
    },
  };
};

export const getOrganisationRequest = (url: string): FetchConfig<Data> => {
  return {
    method: 'GET',
    url: apiRoutes.create(url),
    repository: {
      organisations: (prev, next) => ({ ...prev, [url]: next }),
    },
  };
};

export const updateOrganisationRequest = (
  url: string,
  body: Partial<Organisation>
): FetchConfig<Data> => ({
  method: 'put',
  url: apiRoutes.create(url),
  fetchOptions: {
    body,
    successMsg: i18next.t('form_organisation_update_success'),
  },
  repository: {
    organisations: (prev, next) => ({ ...prev, [url]: next }),
  },
});

export const getOrganisationsSearchRequest = (url: string): FetchConfig<Data> => {
  return {
    method: 'GET',
    url: apiRoutes.create(url),
    optimistic: {
      organisationSearch: () => null,
    },
    repository: {
      organisationSearch: (prev, next) => next,
    },
  };
};

export const getLibrariesWithOrdersRequest = (url: string): FetchConfig<Data> => {
  return {
    method: 'GET',
    url: apiRoutes.create(url),
    repository: {
      libraries: (prev, next) => next,
    },
  };
};

export const createISBNOrganisationRequest = (
  url: string,
  body: Partial<OrganisationCreateRequest>
): FetchConfig<Data> => ({
  method: 'post',
  url: apiRoutes.create(url),
  fetchOptions: {
    body,
  },
  repository: {
    organisation: (prev, next) => next,
  },
});

export const updateISBNOrganisationRequest = (
  url: string,
  body: Partial<Organisation>
): FetchConfig<Data> => ({
  method: 'put',
  url: apiRoutes.create(url),
  fetchOptions: {
    body,
    successMsg: i18next.t('form_organisation_update_success'),
  },
  repository: {
    organisation: (prev, next) => next,
  },
});

export const updateDistributorRequest = (url: string, body: DistributorDto): FetchConfig<Data> => {
  return {
    method: 'PUT',
    url: apiRoutes.create(url),
    fetchOptions: {
      body,
      successMsg: i18next.t('form_organisation_update_success'),
    },
    optimistic: {
      distributors: (prev, next, phase) => {
        if (phase === 'optimistic') {
          return {
            ...prev,
            [url]: {
              ...prev[url],
              ...body,
              orderFileFormat: body.orderFileFormat,
            },
          };
        }
        return prev;
      },
    },
    repository: {
      distributors: (prev, next) => ({ ...prev, [url]: next }),
    },
  };
};

export const getDataConsumerRequest = (url: string): FetchConfig<Data> => ({
  method: 'GET',
  url: apiRoutes.create(url),
  repository: {
    dataConsumers: (prev, next: DataConsumer) => ({ ...prev, [url]: next }),
  },
});

export const updateDataConsumerRequest = (
  url: string,
  body: DataConsumerDTO
): FetchConfig<Data> => ({
  method: 'PUT',
  url: apiRoutes.create(url),
  fetchOptions: {
    body,
    successMsg: i18next.t('form_organisation_update_success'),
  },
  repository: {
    dataConsumers: (prev, next: DataConsumer) => ({ ...prev, [url]: next }),
  },
});

export const generateNewApiKeyRequest = (url: string): FetchConfig<Data> => ({
  method: 'POST',
  url: apiRoutes.create(url),
  repository: {
    dataConsumers: (prev, next: DataConsumer) => ({ ...prev, [next._links.self.href]: next }),
  },
});

export const getBookstoreRequest = (url: string): FetchConfig<Data> => ({
  method: 'GET',
  url: apiRoutes.create(url),
  repository: {
    bookstores: (prev, next: Bookstore) => ({ ...prev, [url]: next }),
  },
});

export const updateBookstoreRequest = (url: string, body: BookstoreDto): FetchConfig<Data> => {
  return {
    method: 'PUT',
    url: apiRoutes.create(url),
    fetchOptions: {
      body,
      successMsg: i18next.t('form_bookstore_update_success'),
    },
    repository: {
      bookstores: (prev, next) => ({ ...prev, [url]: next }),
    },
  };
};

export const getBookstoreGroupsRequest = (url: string): FetchConfig<Data> => {
  return {
    method: 'GET',
    url: apiRoutes.create(url),
    repository: {
      bookstoreGroups: (prev, next) => next,
    },
  };
};

export const getPublisherGroupsRequest = (url: string): FetchConfig<Data> => {
  return {
    method: 'GET',
    url: apiRoutes.create(url),
    repository: {
      publisherGroups: (prev, next) => next,
    },
  };
};

export const getCashierSystemsRequest = (url: string): FetchConfig<Data> => {
  return {
    method: 'GET',
    url: apiRoutes.create(url),
    repository: {
      cashierSystems: (prev, next) => next,
    },
  };
};

export const getOrderFileTypesRequest = (url: string): FetchConfig<Data> => {
  return {
    method: 'GET',
    url: apiRoutes.create(url),
    repository: {
      orderFileTypes: (prev, next) => next,
    },
  };
};

export const getOrganisationFromMembershipForm = (
  organisation: Organisation,
  values: any
): Organisation => {
  const updatedOrganisation = { ...organisation };

  updatedOrganisation.hasMembership = !!(values.active && length(values.active));

  if (values.membershipFrom) {
    updatedOrganisation.membershipFrom = moment(values.membershipFrom, UI_DATE_FORMAT).format(
      `${API_DATE_FORMAT} 00:00:00`
    );
  }

  return updatedOrganisation;
};

export const getLibraryRequest = (url: string): FetchConfig<Data> => ({
  method: 'GET',
  url: apiRoutes.create(url),
  requestKey: 'library',
  repository: {
    library: (prev, next) => next,
  },
});

export const getLibraryOrganisationsRequest = (url: string): FetchConfig<Data> => ({
  method: 'GET',
  url: apiRoutes.create(url),
  requestKey: 'libraries',
  repository: {
    library: (prev, next) => next,
  },
});

export const createDepartmentRequest = (url: string, body: DepartmentDto): FetchConfig<Data> => ({
  method: 'POST',
  url: apiRoutes.create(url),
  fetchOptions: {
    body,
    successMsg: i18next.t('form_department_create_success'),
  },
  repository: {
    department: (prev, next) => next,
    departments: (prev, next) => (prev ? [...prev, next] : [next]),
  },
});

export const getDepartmentsRequest = (url: string): FetchConfig<Data> => ({
  method: 'GET',
  url: apiRoutes.create(url),
  repository: {
    departments: (prev, next) => next,
  },
});

export const updateDepartmentRequest = (url: string, body: Department): FetchConfig<Data> => ({
  method: 'PUT',
  url: apiRoutes.create(url),
  fetchOptions: {
    body,
  },
  repository: {
    departments: (prev, next) => {
      const newState = [...prev] as Departments;
      const prevDep = newState.findIndex((dep) => url.includes(dep.departmentId));
      if (prevDep >= 0) {
        newState[prevDep] = next;
      }
      return newState;
    },
  },
});

export const deleteDepartmentRequest = (url: string): FetchConfig<Data> => ({
  method: 'DELETE',
  url: apiRoutes.create(url),
  repository: {
    departments: (prev, next) => {
      const newState = [...prev] as Departments;
      const prevDep = newState.findIndex((dep) => url.includes(dep.departmentId));
      newState.splice(prevDep, 1);
      return newState;
    },
  },
});

export const getFavoriteBookstoresRequest = (url: string): FetchConfig<Data> => ({
  method: 'GET',
  url: apiRoutes.create(url),
  repository: {
    favoriteBookstores: (prev, next) => next,
  },
});

export const addFavoriteBookstoreRequest = (url: string): FetchConfig<Data> => ({
  method: 'POST',
  url: apiRoutes.create(url),
  repository: {},
});

export const deleteFavoriteBookstoreRequest = (url: string): FetchConfig<Data> => ({
  method: 'DELETE',
  url: apiRoutes.create(url),
  repository: {
    favoriteBookstores: (prev, next) => {
      const result = [...prev];
      const deleted = prev.findIndex((favorite: Bookstore) => url.includes(favorite.bookstoreId));
      result.splice(deleted, 1);
      return result;
    },
  },
});

export const validateUserTransfer = (url: string): FetchConfig<Data> => ({
  method: 'get',
  url: apiRoutes.create(url),
  repository: {},
});

export const postUserTransfer = (url: string): FetchConfig<Data> => ({
  method: 'post',
  url: apiRoutes.create(url),
  repository: {},
});

export const ApiConfig = {
  getOrganisations: () => ({
    resource: 'organisations',
    request: (url?: string) => ({
      url: url ? apiRoutes.create(url) : apiRoutes.organisations(),
    }),
  }),
  createOrganisation: (url: string) => ({
    resource: 'organisation',
    method: 'post',
    request: (organisationDto: OrganisationCreateRequest) => ({
      url: apiRoutes.get(url),
      body: organisationDto,
      meta: {
        successMsg: i18next.t('form_organisation_success'),
      },
    }),
  }),
  updateOrganisationStatus: (url: string, status: boolean) => ({
    resource: 'organisationStatus',
    method: 'put',
    request: () => ({
      url: apiRoutes.create(url),
      body: {
        ftp_enabled: status,
      },
      meta: {
        successMsg: i18next.t('form_ftp_generate_success'),
      },
    }),
  }),
  getRoles: () => ({
    resource: 'roles',
    request: () => ({
      url: apiRoutes.roles(),
    }),
  }),
  getPrefixes: (url: string) => ({
    resource: 'prefixes',
    request: (newUrl: string) => ({
      url: apiRoutes.create(newUrl || url),
    }),
  }),
  createPrefix: (url: string) => ({
    resource: 'prefix',
    method: 'post',
    request: (body: PrefixDto) => ({
      url: apiRoutes.create(url),
      body,
      meta: {
        successMsg: i18next.t('form_prefix_create_success'),
        skipAlertOnError: true,
      },
    }),
  }),
  removePrefix: () => ({
    resource: 'prefix',
    method: 'delete',
    request: (url: string) => ({
      url: apiRoutes.create(url),
      meta: {
        successMsg: i18next.t('form_prefix_remove_success'),
        skipAlertOnError: true,
      },
    }),
  }),
  getCbRelations: (url: string) => ({
    resource: 'cbRelations',
    request: (newUrl: string) => ({
      url: apiRoutes.create(newUrl || url),
    }),
  }),
  createCbRelation: (url: string) => ({
    resource: 'cbRelation',
    method: 'post',
    request: (body: CbRelationDto) => ({
      url: apiRoutes.create(url),
      body,
      meta: {
        successMsg: i18next.t('form_cb_relation_create_success'),
        skipAlertOnError: true,
      },
    }),
  }),
  updateCbRelation: () => ({
    resource: 'cbRelation',
    method: 'put',
    request: (url: string, body: CbRelationDto) => ({
      url: apiRoutes.create(url),
      body,
      meta: {
        successMsg: i18next.t('form_cb_relation_update_success'),
        skipAlertOnError: true,
      },
    }),
  }),
  removeCbRelation: () => ({
    resource: 'cbRelation',
    method: 'delete',
    request: (url: string) => ({
      url: apiRoutes.create(url),
      meta: {
        successMsg: i18next.t('form_cb_relation_remove_success'),
      },
    }),
  }),
  /** @deprecated see updateDistributorRequest */
  updateDistributor: (url: string) => ({
    resource: 'distributor',
    method: 'PUT',
    request: (body: DistributorDto) => ({
      url: apiRoutes.create(url),
      body,
      meta: {
        successMsg: i18next.t('form_organisation_update_success'),
      },
    }),
  }),
  getDataProducer: memoizeOne(() => ({
    resource: 'dataProducer',
    request: (url: string) => ({
      url: apiRoutes.get(url),
    }),
  })),
  updateDataProducer: () => ({
    resource: 'dataProducer',
    method: 'PUT',
    request: (body: DataProducerDto, url: string) => ({
      url: apiRoutes.create(url),
      body,
      meta: {
        successMsg: i18next.t('form_organisation_update_success'),
      },
    }),
  }),

  // ---------
  // Register organisation
  // ---------
  distributor: (url: string) => ({
    resource: 'distributor',
    method: 'post',
    request: (distributorDto: DistributorDto) => ({
      url: apiRoutes.get(url),
      body: distributorDto,
    }),
  }),
  publisher: (url: string) => ({
    resource: 'publisher',
    method: 'post',
    request: () => ({
      url: apiRoutes.get(url),
      body: {},
    }),
  }),
  library: (url: string) => ({
    resource: 'library',
    method: 'post',
    request: () => ({
      url: apiRoutes.get(url),
      body: {},
    }),
  }),
  bookstore: (url: string) => ({
    resource: 'bookstore',
    method: 'post',
    request: () => ({
      url: apiRoutes.get(url),
      body: {},
    }),
  }),
  dataConsumer: (url: string) => ({
    resource: 'dataConsumer',
    method: 'post',
    request: () => ({
      url: apiRoutes.get(url),
      body: {},
    }),
  }),
  dataProducer: (url: string) => ({
    resource: 'dataProducer',
    method: 'post',
    request: () => ({
      url: apiRoutes.get(url),
      body: {},
    }),
  }),
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ApiConfig = ApiConfigUnion<typeof ApiConfig>;
