export const EXTERNAL_LINKS = {
  home: 'https://www.meta4books.be/boekenbank',
  contact: 'https://www.meta4books.be/contactinfo',
  'meta-data': 'https://www.meta4books.be/metadata-afnemen',
  'isbn-info': 'https://www.meta4books.be/isbn',
  'terms-of-service': 'https://www.meta4books.be/gebruikersvoorwaarden',
  'privacy-declaration': 'https://www.meta4books.be/privacy',
  about: 'https://www.meta4books.be/boekenbank',
  'm4b-home': 'https://www.meta4books.be',
  'regulated-book-price': 'https://www.boekenprijs.be/over-site',
  documentation: 'https://documentation.boekenbank.be',
  documentationTitleUpload:
    'https://documentation.boekenbank.be/s/c61tk7tueq8gah7rk05g/eindgebruikersdocumentatie-uitgevers-en-distributeurs/d/c61toddueq8gah7rk06g/titels-in-bulk-wijzigen',
  documentationMediaUpload:
    'https://documentation.boekenbank.be/s/c61tk7tueq8gah7rk05g/eindgebruikersdocumentatie-uitgevers-en-distributeurs/d/c6565nlueq8gah7rk2sg/covers-leesfragment-en-teksten-toevoegen',
};
