import { Typography } from '@mui/material';
import { TypographyProps } from '@mui/material/Typography';
import React from 'react';
import { Publisher as PublisherModel } from '../../../../config/api/types';
import { getLink } from '../../../../helpers/hateoas';
import OrganisationDialog from '../../containers/OrganisationDialog';

type Props = {
  publisher?: PublisherModel;
  withInfoModal?: boolean;
  typographyProps?: TypographyProps;
  showBothIfPossible?: boolean;
};

const PublisherGroup = ({ publisher, withInfoModal, typographyProps }: Props) => {
  const enableModal =
    withInfoModal &&
    publisher &&
    publisher._links &&
    publisher._links.publishingGroupPublisherOrganisation;
  const publisherGroupName = publisher && publisher.publishingGroupName;

  return enableModal ? (
    <OrganisationDialog
      organisationUrl={getLink(publisher, 'publishingGroupPublisherOrganisation') || ''}
      toggleButtonProps={typographyProps}
    >
      {publisherGroupName}
    </OrganisationDialog>
  ) : (
    <Typography display="inline" {...typographyProps}>
      {publisherGroupName}
    </Typography>
  );
};

export default PublisherGroup;
