import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Availability, Fund, Publisher } from '../../../../config/api/types';
import distributor from '../../../organisation/distributor';
import publisherComponents from '../../../organisation/publisher';
import security from '../../../security';

type Props = {
  fund: Fund;
  availability: Availability;
  publisher: Publisher;
};

export const DistributionSection = ({ fund, availability, publisher }: Props) => {
  const roles = useSelector(security.selectors.getUserRole);
  const { t } = useTranslation();

  const distributorName = fund.distributor?.name;
  const isDistributorNameException =
    distributorName && fund.code.startsWith('LAN') && ['12', '23'].includes(availability.code);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
      {fund.distributor?.isCbMember || isDistributorNameException ? (
        <Typography sx={{ textAlign: 'right' }}>{t('title_order_cb')}</Typography>
      ) : (
        <div>
          {/* Distributie door ... */}
          <Typography display="inline">
            {t('title_order_at', { context: roles[0] })}
          </Typography>{' '}
          <distributor.components.Distributor fund={fund} withInfoModal name={distributorName} />
        </div>
      )}

      {publisher.publishingGroupPublisherId ? (
        <div>
          {/* Vertegenwoordiging door ... */}
          <Typography display="inline">{t('title_order_cb_at')}</Typography>{' '}
          <publisherComponents.components.PublisherGroup
            publisher={publisher}
            typographyProps={{
              variant: 'body2',
            }}
            withInfoModal
          />
        </div>
      ) : null}
      {!publisher.publishingGroupPublisherId &&
      !fund.code.startsWith('CBH') &&
      !fund.code.startsWith('CBB') ? (
        <div>
          {/* Vertegenwoordiging door ... */}
          {fund.distributor?.isCbMember || publisher.lannooGroup ? (
            <>
              <Typography display="inline">
                {t('title_order_cb_at', { context: roles[0] })}
              </Typography>{' '}
              <distributor.components.Distributor
                fund={fund}
                withInfoModal
                // name={distributorName} In this case we want to show the name of the distributor organization, not the distributorName
              />
            </>
          ) : null}
        </div>
      ) : null}
    </Box>
  );
};
