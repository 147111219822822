import { FormHelperText, InputLabel, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { Field, FieldProps, FieldRenderProps } from 'react-final-form';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import ClearIndicator from './ReactSelectComponents/ClearIndicator';
import { Control } from './ReactSelectComponents/Control';
import { DropdownIndicator } from './ReactSelectComponents/DropdownIndicator';
import { Menu } from './ReactSelectComponents/Menu';
import { Option } from './ReactSelectComponents/Option';
import { useStyles as useTextFieldStyles } from './TextField';

type AutocompleteProps = Partial<FieldRenderProps<any, any>> & {
  label?: string;
  onClearValue?: () => void;
  isCreatable?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  marginNormal: {
    marginTop: theme.spacing(1),
    marginBottom: 20,
  },
}));

export const Autocomplete = ({
  input,
  label,
  options,
  onClearValue,
  isCreatable,
  components = {},
  disabled,
  ...rest
}: AutocompleteProps) => {
  const textFieldClasses = useTextFieldStyles();
  const classes = useStyles();

  // @ts-ignore
  const selectOptions = options && options.asMutable ? options.asMutable({ deep: true }) : options;

  const SelectComponent = isCreatable ? CreatableSelect : Select;

  return (
    <>
      {label && <InputLabel className={textFieldClasses.label}>{label}</InputLabel>}
      {/* @ts-ignore */}
      <SelectComponent
        isValidNewOption={(inputValue, selectValue, selectOptions: any[]) => {
          const exactMatchExists = selectOptions.some((option) => option.label === inputValue);

          // Allow creation if there's no exact match
          return inputValue && !exactMatchExists;
        }}
        components={{ DropdownIndicator, Menu, Option, Control, ClearIndicator, ...components }}
        className={rest.margin === 'normal' ? classes.marginNormal : textFieldClasses.root}
        placeholder=""
        isDisabled={disabled}
        options={selectOptions}
        {...(input as any)}
        {...rest}
        searchable
        autoComplete={false}
        onClearValue={onClearValue}
        styles={{
          valueContainer: (baseStyles) => ({
            ...baseStyles,
            minHeight: 40,
          }),
          placeholder: (baseStyles) => ({
            ...baseStyles,
            color: 'hsl(0,0%,65%)',
          }),
          noOptionsMessage: (baseStyles) => ({
            ...baseStyles,
            fontSize: 14,
          }),
        }}
      />
      {rest.meta && (rest.meta.submitError || (rest.meta.error && rest.meta.submitFailed)) && (
        <FormHelperText error={true}>{rest.meta.submitError || rest.meta.error}</FormHelperText>
      )}
    </>
  );
};

export type BaseReactSelectFieldProps = FieldProps<any, any> & {};

const BaseReactSelectField = ({ options, ...other }: BaseReactSelectFieldProps) => {
  return (
    <Field
      // parse={val => {
      //   console.log('parse', val, options);
      //   return Array.isArray(val)
      //     ? val.map(v => v.value || v.code)
      //     : val && (val.value || val.code);
      // }}
      // format={val => {
      //   console.log('format', val, options);
      //
      //   return options.filter((o: any) => {
      //     console.log('includes? o', val.includes(o));
      //     return Array.isArray(val)
      //       ? val.includes(o)
      //       : o.value === val || o.code === val;
      //   });
      // }}
      component={Autocomplete}
      options={options}
      {...other}
    />
  );
};

export default BaseReactSelectField;
