import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Box, Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import titleDetail from '..';
import { TitleFull } from '../../../../config/api/types';
import security from '../../../security';
import cart from '../../cart';
import { getOrderTime, getPriceData, getSupplyDate, showOrderTime } from '../../domain';
import EditButton from '../containers/EditButton';
import OrderHistoryButton from '../containers/orderHistory/OrderHistoryButton';
import { DistributionSection } from './DistributionSection';
import Prices from './Prices';
import Prizes from './Prizes';
import TitleStatus from './TitleStatus';

const useStyles = makeStyles((theme: Theme) => ({
  commercial: {
    borderWidth: 1,
    borderStyle: 'solid',
    padding: theme.spacing(2),
    paddingTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    position: 'relative',
  },
  commercial100: {
    borderColor: theme.palette.status.available,
  },
  commercial200: {
    borderColor: theme.palette.status.expected,
  },
  commercial300: {
    borderColor: theme.palette.status.notAvailable,
  },
  addToCart: {
    marginTop: theme.spacing(2),
    flexGrow: 1,
  },
  cta: {
    marginTop: '-0.5rem',
  },
  fullHeight: {
    height: '100%',
  },
}));

type Props = {
  title: TitleFull;
};

const Commercial = ({ title }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const roles = useSelector(security.selectors.getUserRole);

  const orderAtUnknownTexts = roles.find((r) => r === 'ROLE_DISTRIBUTOR')
    ? t('title_order_at_unknown', { context: 'ROLE_DISTRIBUTOR' })
    : roles.map((role) => t('title_order_at_unknown', { context: role })).filter(Boolean)[0];

  const extraClassName = Object.keys(classes).includes(`commercial${title.availability.level}`)
    ? classes[`commercial${title.availability.level}` as keyof typeof classes]
    : '';

  const priceInfos = getPriceData(title);
  const isActionPrice =
    priceInfos[0].label === 'price_action' || priceInfos[0].label === 'price_gbp_action';

  return (
    <Grid
      container
      className={clsx(classes.commercial, extraClassName)}
      sx={{ justifyContent: 'space-between' }}
    >
      {isActionPrice && <titleDetail.PriceTagIcon />}
      <Grid item>
        <Prices title={title} priceInfos={priceInfos} />
        {title.titleLiteraturePrizes?.length > 0 && (
          <Box mt={2}>
            <Prizes awards={title.titleLiteraturePrizes} />
          </Box>
        )}
      </Grid>
      <TitleStatus availability={title.availability} />
      <Grid item className={classes.cta}>
        <Grid
          container
          item
          sx={{ flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'space-between' }}
          className={classes.fullHeight}
        >
          <Grid item>
            {showOrderTime(title, roles) && (
              <Box display={'flex'} alignItems={'center'}>
                <AccessTimeIcon style={{ marginRight: '0.2rem', fontSize: '1rem' }} />
                <Typography display="inline">
                  {t('title_orderTime', {
                    context: getOrderTime(title).translationKey,
                    days: getOrderTime(title).days,
                  })}
                </Typography>
              </Box>
            )}
            {title.availability.supplyDate && (
              <Typography display="inline">
                {t('title_expected_on', {
                  date: getSupplyDate(title),
                })}
              </Typography>
            )}
          </Grid>
          <Grid
            item
            container
            direction="column"
            alignItems="flex-end"
            spacing={1}
            className={classes.addToCart}
          >
            <Box
              flexGrow={1}
              display="flex"
              flexDirection="column"
              alignItems="flex-end"
              flexWrap="wrap"
            >
              <cart.components.AddToCart title={title} />

              {title.fund && (
                <Box mt={1.5}>
                  <Grid item>
                    {!title.fund.isAvailable ? (
                      <DistributionSection
                        fund={title.fund}
                        availability={title.availability}
                        publisher={title.publisher}
                      />
                    ) : (
                      <Typography variant="body2" display="inline">
                        {orderAtUnknownTexts}
                      </Typography>
                    )}
                    {/*<Typography display="inline">{t('title_order_at')}</Typography>*/}
                    {/*<distributor.components.Distributor fund={title.fund} withInfoModal />*/}
                  </Grid>
                </Box>
              )}
            </Box>
            <Box mt={1}>
              <EditButton title={title} />
            </Box>
            <OrderHistoryButton title={title} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Commercial;
