import { Typography } from '@mui/material';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Header from '../../../../../components/Header';
import { EXTERNAL_LINKS } from '../../../../../config/data/external_links';
import ExternalLink from '../../../../app/components/ExternalLink';
import MediaFiles from '../containers/MediaFiles';

// type Props = RouteComponentProps;

const MediaFilesPage = (/*{  }: Props*/) => {
  const { t } = useTranslation();

  return (
    <>
      <Header title={t('page_title_media_files')} />
      <Typography sx={{ mb: 2 }}>
        <Trans
          i18nKey="page_title_title_files_docs"
          components={[<ExternalLink href={EXTERNAL_LINKS['documentationMediaUpload']} />]}
        />
      </Typography>
      <MediaFiles />
    </>
  );
};

export default MediaFilesPage;
