import { useFetch } from '@react-redux-fetch/hooks';
import React, { useEffect } from 'react';
import { Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import DelayedLoader from '../../../../../components/DelayedLoader';
import { DistributorDto, Organisation } from '../../../../../config/api/types';
import { useAsyncValidation } from '../../../../../helpers/finalFormAsyncValidation';
import { getDistributorsRequest } from '../../../../fund/admin/api';
import { updateDistributorRequest } from '../../../api';
import DistributorFormFields from '../../components/Distributor/DistributorFormFields';
import { getDistributor } from '../../selectors';

type Props = {
  organisation?: Organisation;
  goToOrganisation: () => void;
};

const DistributorForm = ({ organisation, goToOrganisation }: Props) => {
  const distributorLink =
    organisation && organisation._links && organisation._links.distributor.href;

  const [, fetchDistributor] = useFetch(getDistributorsRequest);
  const [updateDistributorFetch, updateDistributor] = useFetch(updateDistributorRequest);
  const { createSubmissionPromise } = useAsyncValidation(updateDistributorFetch);
  const distributor = useSelector(getDistributor(distributorLink));

  useEffect(() => {
    if (distributorLink) {
      fetchDistributor(distributorLink);
    }
  }, [distributorLink, fetchDistributor]);

  return (
    <DelayedLoader data={distributor}>
      {distributor && (
        <Form
          initialValues={{
            code: distributor.code,
            isCbMember: distributor.isCbMember,
            processingType: distributor.processingType,
            wantsEmailNotifications: distributor.wantsEmailNotifications,
            orderFileFormat: distributor.orderFileFormat,
            email: distributor.email,
            usesRegulatedPriceAlgorithm: distributor.usesRegulatedPriceAlgorithm,
            canProcessReturns: distributor.canProcessReturns,
            name: distributor.name,
          }}
          onSubmit={(values: DistributorDto) => {
            if (distributorLink) {
              updateDistributor(distributorLink, values);
            }
            return createSubmissionPromise();
          }}
        >
          {({ handleSubmit, dirty, values: { processingType, wantsEmailNotifications } }) => {
            return (
              <DistributorFormFields
                onSubmit={handleSubmit}
                disabled={updateDistributorFetch?.pending || !dirty}
                showFormatField={processingType === 'automatic'}
                showEmailField={wantsEmailNotifications === true}
              />
            );
          }}
        </Form>
      )}
    </DelayedLoader>
  );
};

export default DistributorForm;
