import { useFetch } from '@react-redux-fetch/hooks';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import RemoteAutocomplete from '../../../../../components/Form/RemoteAutocomplete';
import { Publisher } from '../../../../../config/api/types';
import useApiRoute from '../../../../../config/api/useApiRoute';
import { updateQueryParameters } from '../../../../../helpers/hateoas';
import { getPublisherGroupsRequest } from '../../../api';
import { getPublisherGroups } from '../../selectors';

type Props = {
  initialValue?: string;
};

const PublisherGroupField = ({ initialValue }: Props) => {
  const { t } = useTranslation();

  const url = useApiRoute('publishingGroups') || '';

  const [publisherGroupsFetch, fetchPublisherGroups] = useFetch(getPublisherGroupsRequest);
  const publisherGroups = useSelector(getPublisherGroups);

  const createUrl = useCallback(
    (term: string) => {
      return updateQueryParameters(url, { q: term });
    },
    [url]
  );

  useEffect(() => {
    if (initialValue) {
      fetchPublisherGroups(createUrl(initialValue));
    }
  }, [fetchPublisherGroups, initialValue, createUrl]);

  if (!url) {
    return null;
  }

  return (
    <>
      <RemoteAutocomplete
        label={t('form_publisher_group')}
        name="publishingGroupPublisherId"
        options={publisherGroups}
        blurInputOnSelect
        isClearable
        createUrlFromInput={createUrl}
        promiseState={publisherGroupsFetch}
        makeRequest={fetchPublisherGroups}
        getOptionLabel={(option: Publisher) => option.organisation.name}
        getOptionValue={(option: Publisher) => option.publisherId}
        parse={(value: Publisher | null) => value?.publisherId}
        format={(value: string | Publisher) => {
          return publisherGroups?.find((group) => group.publisherId === value) ?? '';
        }}
        wideDropdown
      />
    </>
  );
};

export default PublisherGroupField;
