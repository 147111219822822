import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import React, { FC } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Button';
import { TextField } from '../../../../components/Form';
import useApiRoute from '../../../../config/api/useApiRoute';
import { useDeprecatedAsyncValidation } from '../../../../helpers/finalFormAsyncValidation';
import useFetch from '../../../../helpers/useFetch';
import { UserFeedbackRequest, userFeedbackRequest } from '../api';
import { UserFeedbackCategoriesCheckboxGroup } from './UserFeedbackCategoriesCheckboxGroup';

type Props = {
  onClose: () => void;
  isOpen: boolean;
  gtin13: string;
};
export const UserFeedbackDialog: FC<Props> = ({ onClose, isOpen, gtin13 }) => {
  const { t } = useTranslation();

  const url = useApiRoute('userFeedback') || '';
  const [userFeedbackFetch, postUserFeedback] = useFetch(userFeedbackRequest(url));
  const { createSubmissionPromise } = useDeprecatedAsyncValidation(userFeedbackFetch);
  const theme = useTheme();

  const handleClose = (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason && reason === 'backdropClick' && 'escapeKeyDown') return;
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}>
        {t('userFeedback_dialog_title')}
      </DialogTitle>
      <Form
        onSubmit={(values: UserFeedbackRequest) => {
          postUserFeedback({
            ...values,
            categories: values.categories?.length ? values.categories : undefined,
          });

          return createSubmissionPromise().then((err) => {
            if (err) return err;

            onClose();
          });
        }}
        keepDirtyOnReinitialize
        initialValues={{
          categories: [],
          gtin13,
          message: '',
        }}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Typography>{t('userFeedback_dialog_description')}</Typography>
                <UserFeedbackCategoriesCheckboxGroup />
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  <TextField
                    multiline
                    label={t('userFeedback_dialog_message_label')}
                    rows={5}
                    name="message"
                  />
                </Box>
              </Box>
            </DialogContent>
            <DialogActions>
              <Grid item container spacing={1} alignItems="flex-end" justifyContent="flex-end">
                <Grid item>
                  <Button onClick={onClose} variant="outlined">
                    {t('userFeedback_dialog_action_cancel')}
                  </Button>
                </Grid>
                <Grid item>
                  <Button type="submit">{t('userFeedback_dialog_action_submit')}</Button>
                </Grid>
              </Grid>
            </DialogActions>
          </form>
        )}
      </Form>
    </Dialog>
  );
};
