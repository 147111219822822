import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Box, Divider, Theme, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TitleFull } from '../../../../config/api/types';
import CopyToClipboardButton from '../../../app/components/CopyToClipboardButton';
import publisher from '../../../organisation/publisher';
import { TitleContributors } from '../../catalog/components/Title/TitleContributors';
import { getDate, getFullProductForm, getProductFormDetails } from '../../domain';
import Collection from './Collection';
import { ReportError } from './ReportError';

type Props = {
  title: TitleFull;
};

const useStyles = makeStyles((theme: Theme) => ({
  headerSection: {
    marginBottom: theme.spacing(2),
  },
  title: {
    fontSize: 30,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: theme.typography.h1.lineHeight,
    letterSpacing: theme.typography.h1.letterSpacing,
  },
  subTitle: {
    fontSize: '1.1rem',
  },
  contributors: {
    marginTop: theme.spacing(1),
  },
  sectionTitle: {
    fontSize: '1.1rem',
  },
  metaDivider: {
    margin: `0 ${theme.spacing(1)}`,
    alignSelf: 'stretch',
    height: 'auto',
  },
  copyToClipboardButton: {
    marginLeft: theme.spacing(0.5),
  },
}));

const TitleHeader = ({ title }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const showSupplyDateInsteadOfFirstPublished =
    (title.availability.code === '10' || title.availability.code === '12') &&
    getDate(title, 'availability.supplyDate').isInFuture();

  const titleFormat = showSupplyDateInsteadOfFirstPublished
    ? getDate(title, 'availability.supplyDate').format()
    : getDate(title).format();

  const displayEditionType = () => {
    return !!title.editionTypes?.length && title.feature
      ? `${title.editionTypes.reduce(
          (prev, next) => (prev === '' ? next.name : `${prev}, ${next.name}`),
          ''
        )}, ${title.feature}`
      : `${
          title.editionTypes?.reduce(
            (prev, next) => (prev === '' ? next.name : `${prev}, ${next.name}`),
            ''
          ) ?? ''
        }${title.feature ?? ''}`;
  };

  const displayOriginData = () => {
    return (
      <Typography>
        {t(
          `title_heading_original${title.originalTitle != null ? '_title' : ''}${
            title.originalLanguage?.label != null ? '_language' : ''
          }${title.workFirstPublished != null ? '_publication' : ''}`,
          {
            originalTitle: title.originalTitle,
            originalLanguage: title.originalLanguage?.label,
            workFirstPublished: getDate(title, 'workFirstPublished').momentDate.format('YYYY'),
          }
        )}
      </Typography>
    );
  };

  return (
    <>
      <div className={classes.headerSection}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: 2,
            alignItems: 'flex-start',
          }}
        >
          <Box display="flex">
            <Typography className={classes.title}>
              {title.title}
              <CopyToClipboardButton
                textToCopy={title.title}
                tooltipText={t('copy_title')}
                className={classes.copyToClipboardButton}
              />
            </Typography>
          </Box>
          <Box sx={{ gap: 2, display: 'flex', alignItems: 'center', flexShrink: 0 }}>
            {title.hasInformationRestriction ? (
              <Tooltip title={t('title_restriction_information_tooltip')} placement="left" arrow>
                <VisibilityOffIcon />
              </Tooltip>
            ) : title.sendToGs1 ? (
              <Tooltip title={t('title_restriction_sendToGs1_tooltip')} placement="left" arrow>
                <img src="/static/Logo_GS1.svg" width={32} alt="Logo GS1" />
              </Tooltip>
            ) : null}
            {title.gtin13 ? <ReportError gtin13={title.gtin13} /> : null}
          </Box>
        </Box>
        {title.partTitle && (
          <Typography className={classes.sectionTitle}>
            {title.partTitle} {title.sectionTitle && `(${title.sectionTitle})`}
          </Typography>
        )}
        {title.subtitle && <Typography className={classes.subTitle}>{title.subtitle}</Typography>}
        <Collection collection={title.collection} isDetail />
        {title.contributors.length > 0 && <TitleContributors title={title} isDetail />}
      </div>
      <div className={classes.headerSection}>
        {(title.originalTitle || title.originalLanguage?.label || title.workFirstPublished) &&
          displayOriginData()}
      </div>
      <Typography variant="subtitle1">
        {getFullProductForm(title)}
        {(!!title.editionTypes?.length || title.feature) && ` (${displayEditionType()})`}
        {getProductFormDetails(title)}
      </Typography>
      <Box display="flex" alignItems="center" flexWrap="wrap" className={classes.headerSection}>
        {!!titleFormat && (
          <>
            <Typography variant="subtitle1">{titleFormat}</Typography>
            <Divider orientation="vertical" className={classes.metaDivider} />
          </>
        )}
        <publisher.components.Publisher
          publisher={title.publisher}
          imprint={title.imprint}
          withInfoModal
          typographyProps={{ variant: 'subtitle1' }}
        />
        <Divider orientation="vertical" className={classes.metaDivider} />
        <Typography variant="subtitle1">{title.gtin13}</Typography>
        {title.gtin13 && (
          <CopyToClipboardButton
            textToCopy={title.gtin13}
            tooltipText={t('copy_isbn')}
            className={classes.copyToClipboardButton}
          />
        )}
      </Box>
    </>
  );
};

export default TitleHeader;
