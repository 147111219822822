import * as React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import BaseReactSelectField, {
  BaseReactSelectFieldProps,
} from '../../../../../../components/Form/BaseReactSelectField';
import InfoTooltip from '../../../../../../components/InfoTooltip';
import { EditionType as EditionTypeModel, EditionTypes } from '../../../../../../config/api/types';
import useFetch from '../../../../../../helpers/useFetch';
import { getEditionTypesRequest } from '../../api';
import BibliographicField from '../../components/sections/BibliographicField';

type Props = {
  url: string;
  showEmptyOption?: boolean;
  isMulti?: boolean;
  value?: string | EditionTypes;
} & Omit<BaseReactSelectFieldProps, 'name' | 'value'>;

const EditionType = ({ url, value, showEmptyOption, isMulti, ...other }: Props) => {
  const { t } = useTranslation();
  const [editionTypes, fetchEditionTypes] = useFetch<EditionTypes>(getEditionTypesRequest(url));

  useEffect(() => {
    if (!editionTypes) {
      fetchEditionTypes();
    }
  }, [editionTypes, fetchEditionTypes]);

  return (
    <BibliographicField
      component={BaseReactSelectField}
      label={
        <>
          {t('title_editionTypes')}
          <InfoTooltip title={t('title_editionTypes_helperText')} inline />
        </>
      }
      name="editionTypes"
      defaultValue={isMulti ? value : editionTypes?.value?.find(({ code }) => value === code)}
      options={
        editionTypes && editionTypes.value
          ? [...(showEmptyOption ? [{ name: '' }] : []), ...editionTypes.value]
          : []
      }
      isMulti={isMulti}
      getOptionValue={(option: EditionTypeModel) => option.code}
      getOptionLabel={(option: EditionTypeModel) => option.name}
      disabled={!editionTypes || !editionTypes.value}
      isClearable
      {...other}
    />
  );
};

export default EditionType;
