import { FetchConfig } from '@react-redux-fetch/core';
import i18next from 'i18next';
import memoizeOne from 'memoize-one';
import { Order } from '../../../config/api/models/shop';
import apiRoutes from '../../../config/api/routes';
import { TitleFull, Titles, UserFeedbackDTO } from '../../../config/api/types';
import { Data } from '../../../config/store/types';
import { ApiConfigUnion } from '../../../helpers/apiConfig';

export const getTitleOrderHistoryRequest = (url: string): FetchConfig<Data> => {
  return {
    method: 'GET',
    url: apiRoutes.create(url),
    repository: {
      titleOrders: (prev, next: Order) => ({ ...prev, [url]: next }),
    },
  };
};

export const getTitleRequest = (url: string, gtin13?: string): FetchConfig<Data> => {
  return {
    method: 'GET',
    requestKey: gtin13,
    url: apiRoutes.create(url),
    repository: {
      title: (prev, next: TitleFull | Titles): Data['title'] => {
        return (next._embedded as Titles['_embedded']).items
          ? (next as Titles)._embedded.items.find((i) => i.gtin13 === gtin13)
          : (next as TitleFull);
      },
    },
  };
};

export type UserFeedbackRequest = UserFeedbackDTO;

export const userFeedbackRequest = (url: string) => ({
  resource: 'userFeedback',
  method: 'POST',
  request: (data: UserFeedbackRequest) => ({
    url: apiRoutes.get(url),
    body: data,
    meta: {
      successMsg: i18next.t('userFeedback_dialog_save_success'),
    },
  }),
});

export const getUserFeedbackCategoriesRequest = (url: string): FetchConfig<Data> => {
  return {
    method: 'GET',
    url: apiRoutes.create(url),
    repository: {
      userFeedbackCategories: (prev, next) => next,
    },
  };
};

export const ApiConfig = {
  getTitle: memoizeOne((url: string) => ({
    resource: 'title',
    request: () => ({
      url: apiRoutes.get(url),
      // comparison: url, // No comparison because a refresh is needed after a title file is uploaded and processed
      clearValueOnRequest: true,
    }),
  })),
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ApiConfig = ApiConfigUnion<typeof ApiConfig>;
