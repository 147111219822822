/**
 * WE STARTED REFACTORING THIS TO SEPARATE MODEL FILES
 * SEE api/models DIRECTORY
 */

import { ThemaQualifiers, Themas, TitleKeyword } from './models/dataSets';
import { Bookstore } from './models/organisation';

export type FormattedDate = string; // e.g.: "2019-03-19 13:46:47"

export type Maybe<T> = T | null | undefined;

export interface LoginDTO {
  username: string;
  password: string;
}

export interface LoginResponse {
  token: string;
}

export interface ResourceLinks {
  self: {
    href: string;
  };
}

export type Resource<
  TModel = { [key: string]: any },
  TLinks extends ResourceLinks = {
    self: { href: string };
    [key: string]: { href: string } | undefined;
  },
  TEmbed = any
> = {
  _embedded: TEmbed;
  _links: TLinks;
} & TModel;

export type Paged<TItem> = Resource<
  {
    limit: number;
    page: number;
    pages: number;
    total: number;
  },
  {
    first: { href: 'string' };
    last: { href: 'string' };
    next: { href: 'string' };
    self: { href: 'string' };
    export: { href: 'string' };
    processAll: { href: 'string' };
  },
  {
    items: TItem[];
  }
>;

export type TitleId = string;

export const STATUS_UPLOADED = 'uploaded';
export const STATUS_PROCESSING = 'processing';
export const STATUS_PROCESSED = 'processed';
export const STATUS_PROCESSED_WARNING = 'processed_with_warnings';
export const STATUS_FAILED_CLIENT = 'failed_client';
export const STATUS_FAILED_SERVER = 'failed_server';
export const STATUS_EMPTY = 'empty';

export type StatusType =
  | typeof STATUS_UPLOADED
  | typeof STATUS_PROCESSING
  | typeof STATUS_PROCESSED
  | typeof STATUS_PROCESSED_WARNING
  | typeof STATUS_FAILED_CLIENT
  | typeof STATUS_FAILED_SERVER
  | typeof STATUS_EMPTY;

export type MediaType =
  | 'MEDIA_TYPE_FRONT'
  | 'MEDIA_TYPE_BACK'
  | 'MEDIA_TYPE_SAMPLE_CONTENT'
  | 'MEDIA_TYPE_TEXT_FLAPCOPY'
  | 'MEDIA_TYPE_TEXT_PROMOTIONAL_HEADLINE'
  | 'MEDIA_TYPE_TEXT_ANNOTATION';

export type EBookType = {
  code: string;
  label: string;
};

export type DRM = {
  code: string;
  label: string;
};

export type ProductForm = {
  code: string;
  book: boolean;
  digital: boolean;
  audiovisual: boolean;
  label: string;
  shortLabel: string;
  otherTaxRateAllowed: boolean;
  additionalDescription?: string;
  drm?: DRM;
  details?: {
    code: string;
    label: string;
    eBookType?: EBookType;
  }[];
  taxRate?: TaxRate;
};

export type ProductFormDetail = Record<string, Record<'nl', string>>;

export type Language = {
  code: string;
  label: string;
};

export type EditionType = {
  code: string;
  name: string;
};

export type Availability = {
  code: string;
  label: string;
  level: 100 | 200 | 300;
};

export type Discount = {
  code: DiscountCode;
  label: string;
};

export type TaxRateCode = 'S' | 'R' | 'C';

export type TaxRate = {
  code: TaxRateCode;
  percentageAtHighRate?: number;
  label: string;
};

export type ProductForms = Record<string, ProductForm>;
export type ProductFormDetails = Record<string, ProductFormDetail>;
export type EBookTypes = EBookType[];
export type Languages = Language[];
export type EditionTypes = EditionType[];
export type TaxRates = TaxRate[];
export type Availabilities = Availability[];
export type DiscountCodes = Discount[];
export type Awards = Award[];

export type TitleFile = Resource<
  {
    numSuccess: number;
    numTotal: number;
    path: string;
    processedAt: string;
    source: string;
    status: StatusType;
    uploadedAt: string;
    processedTotal?: number;
  },
  {
    self: { href: string };
    collection: { href: string };
    importReport: { href: string };
    importResults: { href: string };
  }
>;

export type TitleFiles = Paged<TitleFile>;

export type Publishers = Paged<Publisher>;

export type PublisherListItem = Resource<{
  publisherId: string;
  organisation: { countryIso: string; meta4BooksId: string; name: string; organisationId: string };
  prefixes: string[];
}>;

export type PublishersList = Paged<PublisherListItem>;

export type TitleMediaFileImportResult = {
  importMessageCode: string;
  type: string;
  message: string;
  gtin13: string;
  titleMediaSourceFileId: string;
};

export type TitleMediaFile = Resource<
  {
    path: string;
    processedAt: string;
    uploadedAt: string;
    source?: string;
    status: StatusType;
    type: 'MEDIA_TYPE_FRONT' | 'MEDIA_TYPE_BACK' | 'MEDIA_TYPE_SAMPLE_CONTENT';
    byOrganisation?: Organisation;
    byUser?: {
      username: string;
      firstName: string;
      lastName: string;
      email: string;
    };
  },
  {
    self: { href: string };
    collection: { href: string };
  },
  {
    importResult: TitleMediaFileImportResult[];
  }
>;

export type TitleMediaFiles = Paged<TitleMediaFile>;

export type ProcessResultItem = Resource<{
  titleFileId: string;
  importMessageCode: string;
  lineNumber: number;
  gtin13: string;
  message: string;
  type: string;
}>;

export type ProcessResult = {
  facets: {
    info: number;
    warning: number;
    error: number;
  };
  pager: Paged<ProcessResultItem>;
};

export interface FTPCreatedResponse {
  account: string;
  password: string;
}

export type RouteResourceNames =
  | 'login'
  | 'passwordReset'
  | 'passwordResetRequest'
  | 'organisations'
  | 'titleFiles'
  | 'titleMediaFiles'
  | 'titles'
  | 'shoppingCart'
  | 'shoppingCartLines'
  | 'ordersToBeProcessed'
  | 'elasticOrdersToBeProcessed'
  | 'ordersToBeProcessedForBookstore'
  | 'elasticOrdersToBeProcessedForBookstore'
  | 'orderHistoryProcessed'
  | 'elasticOrderHistoryProcessed'
  | 'orderHistory'
  | 'elasticOrderHistory'
  | 'ordersByLibrary'
  | 'elasticOrdersByLibrary'
  | 'order'
  | 'orderFiles'
  | 'orderLineStatus'
  | 'fundsDistributor'
  | 'fundsAdmin'
  | 'fundsBookstore'
  | 'fundsDataConsumer'
  | 'notifications'
  | 'notificationCounters'
  | 'isbnApplication'
  | 'isbnApplicationCreate'
  | 'isbnExport'
  | 'prefixes'
  | 'masterPrefixes'
  | 'prefixApplicationCreate'
  | 'prefixApplication'
  | 'titleProductForms'
  | 'titleProductFormDetails'
  | 'titleContributorRoles'
  | 'titleEbookTypes'
  | 'titleEditionTypes'
  | 'titleLanguages'
  | 'titleAvailabilities'
  | 'titleDiscountCodes'
  | 'titleTaxRates'
  | 'titleAges'
  | 'titleAviLevelsOld'
  | 'titleAviLevelsNew'
  | 'titleKeywords'
  | 'publishers'
  | 'publisherFacet'
  | 'publisherSearch'
  | 'bookstoreSearch'
  | 'bookstores'
  | 'titlesAggregations'
  | 'titleProductFormGroups'
  | 'titleNurs'
  | 'titleThemas'
  | 'titleThemaQualifiers'
  | 'titleAgeRangeGroups'
  | 'titleCollections'
  | 'titleExport'
  | 'reservedIsbnRequest'
  | 'retourReasons'
  | 'retours'
  | 'distributors'
  | 'distributorsDataConsumer'
  | 'quickOrders'
  | 'prizes'
  | 'orderReplacements'
  | 'publishingGroups'
  | 'userFeedback'
  | 'userFeedbackCategory';

export type RouteResource = {
  href: string;
  type: string;
};

export type RoleName = string;

export interface Roles {
  hierarchy: {
    [roleName: string]: RoleName[];
  };
  map: {
    [roleName: string]: RoleName[];
  };
}

export interface FTP {
  organisationId?: string;
  ftpEnabled?: boolean;
  links?: FTPLinks;
}

export interface FTPLinks {
  organisation?: FTPLinksOrganisation;
}

export interface FTPLinksOrganisation {
  href: string;
}

export type Organisation = Resource<
  {
    organisationId?: string;
    name: string;
    meta4BooksId: string;
    membershipRegistrationNumber?: string;
    ftpLogin?: string;
    fixtureId?: string;
    membershipFrom?: string;
    membershipTo?: string;
    companyRegistrationNumber: string;
    street?: string;
    number?: string;
    addition?: string;
    postalCode?: string;
    city?: string;
    countryIso: string;
    email: string;
    phoneNumber: string;
    url: string;
    hasMembership: boolean;
    roles?: Partial<Record<UserRole, boolean>>;
    createdAt: FormattedDate;
    updatedAt: FormattedDate;
  },
  {
    distributor: { href: string };
    dataProducer: { href: string };
    dataConsumer: { href: string };
    self: { href: string };
    ftp?: { href: string };
    funds: { href: string };
    prefixes: { href: string };
    prefixesWithRemainingIsbns?: { href: string };
    cbRelations: { href: string };
    titleFiles?: { href: string };
    logins?: { href: string };
    bookstore: { href: string };
    library: { href: string };
    departmentCurrentUser?: { href: string };
  }
>;

export type Organisations = Paged<Organisation>;

export interface OrganisationCreateRequest {
  name: string;
  membershipRegistrationNumber?: string;
  companyRegistrationNumber: string;
  street: string;
  number: string;
  addition?: string;
  postalCode: string;
  city: string;
  countryIso: string;
  email: string;
  phoneNumber: string;
  url: string;
  roles: Record<RoleName, boolean>;
}

export interface AddShoppingCartLineRequest {
  quantity: number;
  titleId: string;
  reference?: string;
  orderLineReferenceId?: string;
  orderRef?: string;
}

export interface UpdateShoppingCartLineRequest {
  quantity: number;
  userId?: string;
  reference?: string;
  state?: string;
  titleId?: string;
  flowNumber?: number;
}

export interface ConfirmSubOrderRequest {
  orderReference?: string;
  bookstoreId?: string;
  departmentId?: string;
}

export interface PrefixDto {
  prefix: string;
}

export interface BillingType {
  label: string;
  code: 'FLEMISH_OLD' | 'FLEMISH_NEW' | 'N/A';
}

export type BillingTypes = BillingType[];

export type Prefix = Resource<
  PrefixDto & {
    remainingIsbnCount: number;
    billingType: BillingType;
    billed: boolean;
    active: boolean;
    willNotBeBilled: boolean;
    freeIsbnCount: number;
    usedIsbnCount: number;
    occupiedIsbnCount: number;
    reservedIsbnCount: number;
    applicationIsbnCount: number;
    organisationId: string;
  },
  {
    self: { href: string };
  },
  {
    organisation: Organisation;
  }
>;

export type Prefixes = Paged<Prefix>;

export type MasterPrefixDTO = {
  masterPrefix: string;
  description: string;
};

export type MasterPrefix = Resource<
  MasterPrefixDTO & {
    freeIsbnCount: number;
    createdAt: FormattedDate;
  },
  {
    self: { href: string };
  },
  {
    organisation: Organisation;
  }
>;

export type MasterPrefixes = Paged<MasterPrefix>;

export interface CbRelationDto {
  cbRelationId: string;
  isPrimary: boolean;
}

export type CbRelation = Resource<CbRelationDto>;

export type CbRelations = Paged<CbRelation>;

export interface DistributorDto {
  id?: string;
  distributorId?: string;
  code: string;
  isCbMember: boolean;
  processingType: string;
  wantsEmailNotifications: boolean;
  orderFileFormat?: boolean;
  usesRegulatedPriceAlgorithm?: boolean;
  email?: boolean;
  canProcessReturns?: boolean;
  name?: string;
}

export type Distributor = Resource<
  DistributorDto,
  {
    self: { href: string };
    organisation: { href: string };
  },
  {
    organisation: Organisation;
  }
>;

export type DataProducerDto = {
  supplyFormat: 'manual' | 'onix' | 'csv';
  usesBlockUpdates: boolean;
  usesGs1: boolean;
};

export type DataProducer = Resource<
  DataProducerDto,
  {
    self: { href: string };
  },
  {
    organisation: Organisation;
  }
>;

export type StatisticsCode =
  | 'Vlaams'
  | 'Nederlands'
  | 'Import'
  | 'Waals'
  | 'Gemengd'
  | ''
  | undefined;
export type FundStatus = 'status_active' | 'status_not_active';

export type FundDto = {
  code: string;
  description: string;
  isDefault: boolean;
  isAvailable: boolean;
  status: FundStatus;
  statisticsCode?: StatisticsCode;
  distributor?: Distributor;
};

export type Fund = Resource<
  FundDto & {
    organisation?: Organisation;
  },
  {
    self: { href: string };
    organisation: { href: string };
    distributor: { href: string };
  }
>;

export type Funds = Paged<Fund>;

export type UserRole =
  | 'ROLE_ADMIN'
  | 'ROLE_USER'
  | 'ROLE_PUBLISHER'
  | 'ROLE_DISTRIBUTOR'
  | 'ROLE_LIBRARY'
  | 'ROLE_DATA_CONSUMER'
  | 'ROLE_DATA_PRODUCER'
  | 'ROLE_BOOKSTORE';

export type User = Resource<
  {
    id?: string;
    username?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    roles?: UserRole[];
  },
  {
    self: { href: string };
    organisation: { href: string };
  },
  {
    organisation?: Organisation;
    department?: Department;
    bookstore?: {
      bookstoreId?: string;
      isMemberBookstoresFlanders: boolean;
      organisationId: string;
      usesFlowNumber: boolean;
      wantsEmailNotifications: boolean;
      _links: {
        unConfirmedRetour?: {
          href: string;
        };
      };
    };
  }
>;

export type AwardCode = '01' | '02' | '03' | '04' | '05' | '06' | '07';

export type Award = {
  result: {
    result: string;
    code: AwardCode;
  };
  literaturePrize: LiteraturePrize;
  year: string;
};

export type LiteraturePrize = {
  cbName?: string;
  code?: number;
  description?: string;
  id: string;
  name: string;
  source?: string;
  keywords?: string[];
};

export type AwardsData = {
  prizes: LiteraturePrize[];
  results: Record<AwardCode, string>;
};

export type TitleLiteraturePrize = {
  code: AwardCode;
  literaturePrize: LiteraturePrize;
  result: string;
  year: string;
};

/*
A01 - Auteur
A12 - Illustrator
A13 - Fotograaf
B01 - Redacteur
B06 - Vertaler
E07 - Voorlezer
 */

export type ContributorRole = {
  code: 'A01' | 'A12' | 'A13' | 'B01' | 'B06' | 'E07';
  label: string;
};

export type PersonContributor = {
  firstName?: string;
  lastName: string;
  name: string;
  role: ContributorRole;
  roleCode: ContributorRole['code'];
  titleContributorId: string;
  titleId: string;
  sequenceNumber: number;
};

export type CorporateContributor = {
  corporateName: string;
  role: ContributorRole;
  roleCode: ContributorRole['code'];
  titleContributorId: string;
  titleId: string;
  sequenceNumber: number;
};

export type Contributor = PersonContributor | CorporateContributor;
export type DiscountCode = 'A' | 'W' | 'S' | 'O';

export type PublisherDTO = {
  usesRegulatedPriceAlgorithm: boolean;
  isPurchaseOrderRequired: boolean;
  isMemberGauGewu: boolean;
  canChangeGprc: boolean;
  isDefaulter: boolean;
  sendDataToCb: boolean;
  isPublisherGroup: boolean;
  canDisablePublishingGroup: boolean;
  publishingGroupPublisherId?: string;
  publishingGroupPublisherOrganisationId?: string;
  publishingGroupName?: string;
  lannooGroup: boolean;
};

export type Publisher = Resource<
  PublisherDTO & {
    organisation: Organisation;
    publisherId: string;
  },
  {
    self: { href: string };
    organisation?: { href: string };
    publishingGroupPublisherOrganisation?: { href: string };
  }
>;

export type LibraryDTO = {};

export type Library = Resource<
  LibraryDTO & {
    libraryId: string;
    organisation: Organisation;
  },
  {
    self: { href: string };
    organisation?: { href: string };
  }
>;

export type DepartmentDto = {
  title: string;
  library: Library;
};

export type Department = Resource<
  DepartmentDto & {
    departmentId: string;
  },
  {
    self: { href: string };
  }
>;

export type Departments = Department[];

export type FavoriteBookstores = Bookstore[];

export type Imprint = string;

export type MediaCover = {
  thumbnail: string;
  thumbnailSmall: string;
  cover?: string;
  coverBig?: string;
  original?: string;
  type: 'MEDIA_TYPE_FRONT' | 'MEDIA_TYPE_BACK';
  id: string;
  _links?: {
    self?: {
      href: string;
    };
  };
};

export type MediaSample = {
  type: 'MEDIA_TYPE_SAMPLE_CONTENT';
  original?: string;
  id: string;
  _links?: {
    self?: {
      href: string;
    };
  };
};

export type Media = MediaCover | MediaSample;

export type EmbeddedTitle = Resource<
  {
    availability: Availability & {
      supplyDate?: string;
    };
    gtin13: string;
    isOrderableByBookstore: boolean;
    isOrderableByLibrary: boolean;
    isbnFirstPublished: string;
    price: number;
    title: string;
    productForm: ProductForm;
    languages?: Language[];
  },
  {
    self: { href: string };
  },
  {
    media?: Media[];
  }
>;

export type Title = Resource<
  {
    availability: Availability & {
      supplyDate?: string;
    };
    orderTime?: number | '';
    gtin13?: string;
    title: string;
    subtitle?: string;
    collection?: {
      number?: string;
      title?: string;
    };
    editionTypes?: EditionTypes;
    productForm: ProductForm;
    contributors: Contributor[];
    titleLiteraturePrizes: TitleLiteraturePrize[];
    imprint?: Imprint;
    languages?: Languages;
    isbnFirstPublished?: string;
    isbnFirstPublishedDate?: string;
    inBookshelf?: boolean;
    price?: number;
    priceIsFreeOfCharge?: boolean;
    priceIsToBeAnnounced?: boolean;
    discountCode?: {
      code: DiscountCode;
      label: string;
    };
    fund: Fund;
    priceAction?: {
      endDate: string;
      price: number;
      startDate: string;
    };
    priceRegulated?: {
      endDate: string;
      price: number;
      startDate: string;
    };
    priceRegulatedAction?: {
      description?: string;
      endDate: string;
      price: number;
      startDate: string;
    };
    priceRegulatedActivated: boolean;
    publisher: Publisher;
    isOrderableByBookstore: boolean;
    isOrderableByLibrary: boolean;
    hasInformationRestriction: boolean;
    sendToGs1: boolean;
    hasOrderRestriction: boolean;
  },
  {
    self: { href: string };
    fund: { href: string };
    orderHistory?: { href: string };
    titleTitleMediaSourceFiles?: { href: string };
  },
  {
    media?: Media[];
    titleReplacedBy?: EmbeddedTitleReplacement;
    titleReplacementOf?: EmbeddedTitleReplacement;
    matchingNstc?: EmbeddedTitle[];
    matchingLanguages?: EmbeddedTitle[];
  }
>;

export type EmbeddedTitleReplacement = {
  gtin13: string;
  isOrderableByBookstore: boolean;
  isOrderableByLibrary: boolean;
};

export type TitleFull = Title & {
  aviLevelsNew?: { code: string }[];
  aviLevelsOld?: { code: string }[];
  ageRange?: {
    from?: {
      qualifier: string;
      value: number;
    };
    to?: {
      qualifier: string;
      value: number;
    };
  };
  sectionTitle?: string;
  partTitle?: string;
  originalTitle?: string;
  annotation?: string;
  flapCopy?: string;
  nur?: {
    code: string;
    label: string;
  };
  themas?: Themas;
  themaQualifiers?: ThemaQualifiers;
  editionNumber?: number;
  editionDescription?: string;
  numPages?: number;
  height?: number;
  width?: number;
  thickness?: number;
  weight?: number;
  originalLanguage?: Language;
  promotionalHeadline?: string;
  workFirstPublished?: string;
  legalDepotNumber?: string;
  createdAt: FormattedDate;
  updatedAt: FormattedDate;
  changedAt?: FormattedDate;
  createdBy?: string;
  updatedBy?: string;
  changedBy?: string;
  duration?: string;
  illustrationsNote?: string;
  replacedBy?: string;
  replacementOf?: string;
  titleInOtherLanguages?: { gtin13: string }[];
  feature?: string;
  nstc?: string;
  isBibliographicalVerified?: boolean;
  keywords: TitleKeyword[];
};

export type Facet = {
  key: string;
  docCount: number;
};

export type Titles = Paged<TitleFull> & {
  aggregations: {
    'productForm.group.code': Facet[];
  };
};

export type IsbnDTO = {
  login: {
    firstName: string;
    lastName: string;
    email: string;
  };
  organisation: {
    name: string;
    companyRegistrationNumber: string;
    street: string;
    number: string;
    addition: string;
    postalCode: string;
    city: string;
    countryIso: string;
    email: string;
    phoneNumber: string;
    url: string;
  };
  title: Partial<TitleFull>;
  prefix: string;
  requestIsbnNumbers: string;
};

export type IsbnApplicationState = 'new' | 'on_hold' | 'rejected' | 'approved';

export type IsbnApplication = Resource<
  IsbnDTO & {
    title: {
      title: string;
      productForm: ProductForm;
      languages: Languages;
      availability: Availability;
      editionNumber: number;
    };
    createdAt: string;
    updatedAt: string;
    state: IsbnApplicationState;
    publisherOrganisationId?: string;
    anonymous: boolean;
    onHoldComment?: string;
    purchaseOrderNumber?: string;
  },
  {
    self: { href: string };
  },
  {
    publisherOrganisation?: Organisation;
  }
>;

export type IsbnApplications = Paged<IsbnApplication>;

export type ReserveIsbnDto = {
  amount: number;
  prefix: string;
};

export type ReservedIsbnNumber = {
  gtin13: string;
  state: string;
};

export type ReservedIsbnNumbers = ReservedIsbnNumber[];

export type PrefixApplicationDto = {
  login: {
    firstName?: string;
    lastName: string;
    email: string;
  };
  organisation: {
    name: string;
    companyRegistrationNumber: string;
    street: string;
    number: string;
    addition: string;
    postalCode: string;
    city: string;
    countryIso: string;
    email: string;
    phoneNumber: string;
    url: string;
  };
  prefixSize: string;
  masterPrefixToUse: string;
  prefix: string;
};

export type PrefixApplication = Resource<
  {
    organisation: Organisation;
    creator: {
      firstName?: string;
      lastName: string;
      email: string;
    };
    prefixSize: string;
    prefix?: string;
    state: IsbnApplicationState;
    createdAt: string;
    updatedAt: string;
    purchaseOrderNumber?: string;
  },
  {
    self: { href: string };
    publisherOrganisation: { href: string };
  },
  {
    publisherOrganisation: Organisation;
  }
>;

export type PrefixApplications = Paged<PrefixApplication>;

export type ContributorRoles = { code: string; label: string }[];

export type UserFeedbackDTO = {
  categories?: string[];
  gtin13: string;
  message: string;
  organisationId?: string;
  email?: string;
  organisationName?: string;
};
