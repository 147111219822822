import { Typography } from '@mui/material';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import Header from '../../../../../components/Header';
import { EXTERNAL_LINKS } from '../../../../../config/data/external_links';
import ExternalLink from '../../../../app/components/ExternalLink';
import TitleFiles from '../containers/TitleFiles';

type Props = RouteComponentProps & {};

const TitleFilesPage = (props: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Header title={t('page_title_title_files')} />
      <Typography sx={{ mb: 2 }}>
        <Trans
          i18nKey="page_title_title_files_docs"
          components={[<ExternalLink href={EXTERNAL_LINKS['documentationTitleUpload']} />]}
        />
      </Typography>
      <TitleFiles />
    </>
  );
};

export default TitleFilesPage;
