import { Box, Typography } from '@mui/material';
import { useFetch } from '@react-redux-fetch/hooks';
import React, { useEffect } from 'react';
import { useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CheckBox } from '../../../../components/Form';
import useApiRoute from '../../../../config/api/useApiRoute';
import { getUserFeedbackCategoriesRequest, UserFeedbackRequest } from '../api';
import { getUserFeedbackCategories } from '../selectors';

export const UserFeedbackCategoriesCheckboxGroup = () => {
  const { t } = useTranslation();
  const { submitErrors } = useFormState<UserFeedbackRequest>();
  const error = submitErrors?.categories;

  const [, fetchUserFeedbackCategories] = useFetch(getUserFeedbackCategoriesRequest);
  const url = useApiRoute('userFeedbackCategory');
  const categories = useSelector(getUserFeedbackCategories);

  useEffect(() => {
    if (url) {
      fetchUserFeedbackCategories(url);
    }
  }, [fetchUserFeedbackCategories, url]);

  if (!url) {
    return null;
  }

  return (
    <Box>
      <Typography sx={{ mb: 1, fontWeight: 500 }}>
        {t('userFeedback_dialog_categories_label')}
      </Typography>
      {categories
        ? Object.entries(categories).map(([value, label]) => {
            return (
              <CheckBox
                name="categories"
                value={value}
                label={label}
                key={value}
                validate={() => null}
              />
            );
          })
        : null}
      {error ? (
        <Typography color="error" sx={{ fontSize: '.75rem' }}>
          {error}
        </Typography>
      ) : null}
    </Box>
  );
};
