import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Button from '../../../../components/Button';
import { useDisclosure } from '../../../../helpers/useDisclosure';
import security from '../../../security';
import { UserFeedbackDialog } from './UserFeedbackDialog';

type Props = {
  gtin13: string;
};

export const ReportError = ({ gtin13 }: Props) => {
  const { t } = useTranslation();

  const userRoles = useSelector(security.selectors.getUserRole) || [];
  const isBookstore = userRoles.includes('ROLE_BOOKSTORE');
  const isDataConsumer = userRoles.includes('ROLE_DATA_CONSUMER');
  const isLibrary = userRoles.includes('ROLE_LIBRARY');
  const { isOpen, onClose, onOpen } = useDisclosure();

  if (!isBookstore && !isDataConsumer && !isLibrary) return null;

  return (
    <>
      <Button onClick={onOpen}>{t('report_error')}</Button>
      <UserFeedbackDialog onClose={onClose} isOpen={isOpen} gtin13={gtin13} />
    </>
  );
};
