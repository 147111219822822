import { Box, Grid } from '@mui/material';
import React from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../components/Button';
import Field from '../../../../../components/Form';
import { DepartmentDto } from '../../../../../config/api/types';

type Props = {
  onSubmit: (values: DepartmentDto) => void;
  onClose: () => void;
};

const DepartmentForm = ({ onSubmit, onClose }: Props) => {
  const { t } = useTranslation();

  return (
    <Form
      initialValues={{ title: '' } as DepartmentDto}
      onSubmit={(values: DepartmentDto) => onSubmit(values)}
    >
      {({ handleSubmit, submitting, form: { reset } }) => (
        <form
          onSubmit={(event) => {
            if (!handleSubmit) {
              return;
            }
            const promise = handleSubmit(event);
            if (promise) {
              promise.then((err) => {
                if (!err) {
                  reset();
                }
              });
            }
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Box sx={{ maxWidth: '400px' }}>
              <Field type="text" name="title" label={t('form_department_name')} />
            </Box>

            <Grid container spacing={2}>
              <Grid item>
                <Button type="submit" disabled={submitting}>
                  {t('form_action_save')}
                </Button>
              </Grid>
              <Grid item>
                <Button variant="outlined" disabled={submitting} onClick={onClose}>
                  {t('form_action_cancel')}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </form>
      )}
    </Form>
  );
};

export default DepartmentForm;
