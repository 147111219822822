import { TitleOrder, TitleOrderRef } from '../../../config/api/models/shop';
import { Maybe } from '../../../config/api/types';
import { RootState } from '../../../config/store/types';

export const getTitleOrderHistory =
  (titleOrderRef: TitleOrderRef) =>
  (state: RootState): Maybe<TitleOrder[]> =>
    state.data.titleOrders?.[titleOrderRef];

export const getUserFeedbackCategories = (state: RootState): Maybe<Record<string, string>> =>
  state.data.userFeedbackCategories;
