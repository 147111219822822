import { Box } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Button';
import { BaseTextField } from '../../../../components/Form/TextField';
import { IsbnApplication } from '../../../../config/api/types';

type Props = {
  isbnApplication: IsbnApplication;
  onUpdate: (isbnApplication: IsbnApplication) => void;
};

const EditIsbnPurchaseOrderWrapper = ({ isbnApplication, onUpdate }: Props) => {
  const { t } = useTranslation();
  const [purchaseOrder, setPurchaseOrder] = useState<string>(
    isbnApplication.purchaseOrderNumber || ''
  );

  return (
    <Box sx={{ width: 400 }}>
      <BaseTextField
        label={t('form_isbn_purchase_order_number')}
        value={purchaseOrder}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setPurchaseOrder(event.target.value)
        }
      />

      <Button onClick={() => onUpdate({ ...isbnApplication, purchaseOrderNumber: purchaseOrder })}>
        {t('form_action_save')}
      </Button>
    </Box>
  );
};

export default EditIsbnPurchaseOrderWrapper;
